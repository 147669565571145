import { toast } from "@zerodevx/svelte-toast";
export const success = (message) => toast.push(message, {
    theme: {
        "--toastBackground": "green",
        "--toastColor": "white",
        "--toastBarBackground": "olive",
    },
});
export const failure = (message) => toast.push(message, {
    theme: {
        "--toastBackground": "red",
        "--toastColor": "white",
        "--toastBarBackground": "olive",
    },
});
